import './NoMatches.scss';

import React from 'react';

interface Props {
  resetFilters(): void;
}

const NoMatches: React.FC<Props> = (props) => {
  const { resetFilters } = props;

  return (
    <div className="FrameworkFiltersNoMatches">
      No Frameworks Matching Current Filters{' '}
      <button
        type="button"
        className="FrameworkFiltersNoMatches-resetFilters"
        onClick={resetFilters}
      >
        Clear Filters
      </button>
    </div>
  );
};

export default NoMatches;
