import './compare-frameworks.scss';

import React from 'react';
import { graphql } from 'gatsby';
import { sortBy } from 'lodash';

import Layout from 'components/layout/Layout';
import CompareFrameworks from 'components/compare-frameworks/CompareFrameworks';
import VisualToolLinks from 'components/VisualToolLinks';

import { Framework, Term } from 'types';

interface Props {
  location: {
    state: any;
  } | null;
  data: {
    allFrameworks: {
      frameworks: Framework[];
    };
    allTerms: {
      terms: Term[];
    };
  };
}

const CompareFrameworksPage: React.FC<Props> = (props) => {
  const { terms } = props.data.allTerms;

  const initialFrameworkID = props?.location?.state?.frameworkID;

  const frameworks = sortBy(
    props.data.allFrameworks.frameworks,
    (f) => f.name
  ).filter((f) => terms.some((t) => t.frameworkID === f.identifier));

  return (
    <Layout>
      <h1>Compare Skills Between Frameworks</h1>
      <div className="CompareFrameworksPage-description">
        <p className="emphasis">
          Different frameworks might refer to the same or similar skills using
          different names, or call two very different skills by the same name.
          This makes it hard to tell whether or not frameworks are addressing
          similar skills.
        </p>
        <p className="emphasis no-print">
          Select a framework from each list to see where terms in the left
          framework are related to terms in the right one – based on their
          definitions rather than their names. The thicker the line, the more
          related the terms.
        </p>
      </div>

      <CompareFrameworks
        frameworks={frameworks}
        terms={terms}
        initialFrameworkID={initialFrameworkID}
      />

      <VisualToolLinks excludeVisualToolLink="frameworks" />
    </Layout>
  );
};

export default CompareFrameworksPage;

export const query = graphql`
  query CompareFrameworksQuery {
    allFrameworks: allFrameworksJson {
      frameworks: nodes {
        identifier
        name
        color
        # filterAttributes {
        #   developerType
        #   documentType
        #   focus
        #   ageRange
        #   language
        #   setting
        #   regionOfOrigin
        #   countryOfOrigin
        #   regionOfUse
        #   countryOfUse
        #   countryOfOriginIncomeLevel
        #   countryOfUseIncomeLevel
        # }
      }
    }
    allTerms: allTermsJson {
      terms: nodes {
        identifier
        name
        frameworkID
        frameworkName
        parentID
        tier
        definition
        includeInThesaurus
        relatedTerms {
          identifier
          relatedness
        }
      }
    }
  }
`;
