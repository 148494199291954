import './VisualToolControls.scss';

import React from 'react';

import PrintButton from 'components/core/PrintButton';

import { FrameworkFilters } from 'components/framework-filters';
import { FrameworkFilters as Filters } from 'components/framework-filters/useFrameworkFilters';

interface Props {
  filters: Filters;
}

const VisualToolControls: React.FC<Props> = (props) => {
  const { filters } = props;

  return (
    <div className="VisualToolControls">
      <FrameworkFilters filters={filters} />

      <div className="VisualToolControls-print">
        <PrintButton />
      </div>
    </div>
  );
};

export default VisualToolControls;
