import './CompareFrameworksPlaceholder.scss';

import React from 'react';

const CompareFrameworksPlaceholder: React.FC = () => {
  return (
    <div className="CompareFrameworksPlaceholder">
      <svg viewBox="0 0 775 285" version="1.1">
        <g stroke="#E6E6E6" strokeWidth="2" fill="none">
          <rect x="1" y="132" width="95" height="21" />
          <rect x="171" y="1" width="95" height="21" />
          <rect x="171" y="66" width="95" height="21" />
          <rect x="171" y="132" width="95" height="21" />
          <rect x="171" y="197" width="95" height="21" />
          <rect x="171" y="263" width="95" height="21" />
          <rect x="679" y="132" width="95" height="21" />
          <rect x="509" y="66" width="95" height="21" />
          <rect x="509" y="197" width="95" height="21" />
          <rect x="509" y="131" width="95" height="21" />
          <path d="M510,208 C386.420538,208 382.855746,77 267,77" />
          <path d="M510,208 C386.420538,208 382.855746,10 267,10" />
          <path
            d="M679,208 C641.366748,208 640.281174,143 605,143"
            transform="translate(642.000000, 175.500000) scale(1, -1) translate(-642.000000, -175.500000)"
          />
          <path d="M679,143 C641.366748,143 640.281174,76 605,76" />
          <path
            d="M510,208 C386.420538,208 382.855746,141 267,141"
            transform="translate(388.500000, 174.500000) scale(1, -1) translate(-388.500000, -174.500000)"
          />
          <path
            d="M510,275 C386.420538,275 382.855746,141 267,141"
            transform="translate(388.500000, 208.000000) scale(1, -1) translate(-388.500000, -208.000000)"
          />
          <path
            d="M171,143 C133.366748,143 132.281174,10 97,10"
            transform="translate(134.000000, 76.500000) scale(1, -1) translate(-134.000000, -76.500000)"
          />
          <path d="M171,211 C133.366748,211 132.281174,144 97,144" />
        </g>
      </svg>
    </div>
  );
};

export default CompareFrameworksPlaceholder;
