import './VisualToolLinks.scss';

import React from 'react';

import {
  CompareDomainsLink,
  CompareFrameworksLink,
  CompareTermsLink
} from 'components/VisualToolLink';

interface Props {
  excludeVisualToolLink: 'domains' | 'frameworks' | 'terms';
}

const VisualToolLinks: React.FC<Props> = (props) => {
  const { excludeVisualToolLink } = props;

  return (
    <div className="VisualToolLinks no-print">
      <h3 className="VisualToolLinks-heading">Explore Other Visualizations</h3>
      <div className="VisualToolLinks-wrapper">
        {excludeVisualToolLink !== 'domains' && (
          <CompareDomainsLink displayStyle="banner" />
        )}
        {excludeVisualToolLink !== 'frameworks' && (
          <CompareFrameworksLink displayStyle="banner" />
        )}
        {excludeVisualToolLink !== 'terms' && (
          <CompareTermsLink displayStyle="banner" />
        )}
      </div>
    </div>
  );
};

export default VisualToolLinks;
