import './FrameworkSelect.scss';

import React from 'react';

import { Framework } from 'types';

import downArrow from 'assets/images/down-arrow.svg';

interface Props {
  selectedFrameworkID: number | null;
  disabledFrameworkID: number | null;
  onChange: (event: React.SyntheticEvent<HTMLSelectElement>) => void;
  frameworks: Framework[];
}

const FrameworkSelect: React.FC<Props> = (props) => {
  const { selectedFrameworkID, disabledFrameworkID, onChange, frameworks } =
    props;

  const selectedFramework = selectedFrameworkID
    ? frameworks.find((f) => f.identifier === selectedFrameworkID)
    : null;

  return (
    <div className="FrameworkSelect">
      <select
        id="FrameworkSelect-select--left"
        value={selectedFrameworkID || ''}
        onChange={onChange}
        className="FrameworkSelect-select no-print"
        style={{ backgroundImage: `url(${downArrow})` }}
      >
        {selectedFrameworkID == null && (
          <option value="0">Select a Framework...</option>
        )}

        {frameworks.map((framework) => (
          <option
            key={framework.identifier}
            value={framework.identifier}
            disabled={framework.identifier === disabledFrameworkID}
          >
            {framework.name}
          </option>
        ))}
      </select>
      <div className="print-only">{selectedFramework?.name}</div>
    </div>
  );
};

export default FrameworkSelect;
