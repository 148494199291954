import './CompareFrameworks.scss';

import React, { useEffect } from 'react';

import { usePersistentState } from 'utils/usePersistentState';
import { Framework, Term } from 'types';

import { useFrameworkFilters, NoMatches } from 'components/framework-filters';
import VisualToolControls from 'components/VisualToolControls';

import FrameworkSelect from './FrameworkSelect';
import CompareFrameworksGraph from './CompareFrameworksGraph';
import CompareFrameworksPlaceholder from './CompareFrameworksPlaceholder';

interface Props {
  frameworks: Framework[];
  terms: Term[];
  initialFrameworkID: number | null;
}

/**
 * Includes the tree diagrams for comparing frameworks (handled by another
 * component), and manages the controls and state for the visualization.
 */
const CompareFrameworks: React.FC<Props> = (props) => {
  const { frameworks, terms, initialFrameworkID } = props;

  const filters = useFrameworkFilters(frameworks);
  const filteredFrameworks = filters.frameworks;

  const [leftFrameworkID, setLeftFrameworkID] = usePersistentState<
    number | null
  >('compare-frameworks-left', null);
  const [rightFrameworkID, setRightFrameworkID] = usePersistentState<
    number | null
  >('compare-frameworks-right', null);

  // Deselect frameworks if they're not included in the current, filtered list.
  useEffect(() => {
    if (
      leftFrameworkID &&
      !filteredFrameworks.find((f) => f.identifier === leftFrameworkID)
    ) {
      setLeftFrameworkID(null);
    }
    if (
      rightFrameworkID &&
      !filteredFrameworks.find((f) => f.identifier === rightFrameworkID)
    ) {
      setRightFrameworkID(null);
    }
  }, [
    filteredFrameworks,
    leftFrameworkID,
    rightFrameworkID,
    setLeftFrameworkID,
    setRightFrameworkID
  ]);

  useEffect(() => {
    if (initialFrameworkID) {
      setLeftFrameworkID(initialFrameworkID);
      setRightFrameworkID(null);
    }
  }, [initialFrameworkID, setLeftFrameworkID, setRightFrameworkID]);

  const onChangeFramework = (
    side: 'left' | 'right',
    event: React.SyntheticEvent<HTMLSelectElement>
  ) => {
    const id = parseInt(event.currentTarget.value, 10);
    if (side === 'left') {
      setLeftFrameworkID(id);
    } else {
      setRightFrameworkID(id);
    }
  };

  const reset = () => {
    setLeftFrameworkID(null);
    setRightFrameworkID(null);
  };

  const frameworkWithID = (id: number | null): Framework | null => {
    if (id == null) {
      return null;
    }
    return frameworks.find((framework) => framework.identifier === id) || null;
  };

  return (
    <div className="CompareFrameworks">
      <div className="CompareFrameworks-filters no-print">
        <VisualToolControls filters={filters} />
      </div>

      {filters.frameworks.length === 0 ? (
        <NoMatches resetFilters={filters.reset} />
      ) : (
        <>
          <div className="CompareFrameworks-controls no-print">
            {(leftFrameworkID || rightFrameworkID) && (
              <button
                onClick={reset}
                type="button"
                className="CompareFrameworks-resetButton"
              >
                Reset
              </button>
            )}
          </div>

          <div className="CompareFrameworks-inputs">
            <FrameworkSelect
              selectedFrameworkID={leftFrameworkID}
              disabledFrameworkID={rightFrameworkID}
              onChange={onChangeFramework.bind(null, 'left')}
              frameworks={filteredFrameworks}
            />

            <FrameworkSelect
              selectedFrameworkID={rightFrameworkID}
              disabledFrameworkID={leftFrameworkID}
              onChange={onChangeFramework.bind(null, 'right')}
              frameworks={filteredFrameworks}
            />
          </div>

          {leftFrameworkID || rightFrameworkID ? (
            <CompareFrameworksGraph
              leftFramework={frameworkWithID(leftFrameworkID)}
              rightFramework={frameworkWithID(rightFrameworkID)}
              terms={terms}
            />
          ) : (
            <CompareFrameworksPlaceholder />
          )}
        </>
      )}
    </div>
  );
};

export default CompareFrameworks;
