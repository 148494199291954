import './CompareFrameworksGraph.scss';

import React from 'react';
import { intersection } from 'lodash';
import { scaleLinear } from 'd3-scale';
import { tree as d3Tree } from 'd3-hierarchy';

import FrameworkGraphNode from 'components/tree-diagrams/FrameworkGraphNode';

import {
  linkPath,
  transformCoordinates
} from 'components/tree-diagrams/d3-extensions';

import {
  stratifyFramework,
  termsAreRelated
} from 'components/tree-diagrams/framework-attributes';
import { iterateCombinationsBetween } from 'utils/collections';
import { Size, Rect, EdgeInsets } from 'utils/geometry';
import { Framework, Term } from 'types';

import ScreenReaderContent from './ScreenReaderContent';

interface Props {
  leftFramework: Framework | null;
  rightFramework: Framework | null;
  terms: Term[];
}

interface State {
  hoveredTerm: Term | null;
}

const strokeWidthScale = scaleLinear().domain([0, 1]).range([1.5, 10]);

/**
 * A visualization showing the similar terms between two frameworks. Each
 * framework is drawn as a tree branching from the left and right sides. Lines
 * are drawn between related terms in frameworks.
 *
 * Theres some duplication between this and the `FrameworkGraph`, which may
 * be possible to extract.
 *
 * The diagram is drawn with a viewport size that increases with the number of
 * terms in the frameworks. The SVG is then scaled so a larger diagram will have
 * smaller text.
 */
export default class CompareFrameworksGraph extends React.PureComponent<
  Props,
  State
> {
  // These values are in a more or less arbitrary coordinate space: in pixels
  // relative to the SVG view box, which is scaled to fit in the page.
  elementSize = new Size(150, 24);
  elementSpacing = new Size(50, 14);
  margin = new EdgeInsets(
    0,
    this.elementSize.width / 2.0,
    0,
    this.elementSize.width / 2.0
  );
  spaceBetweenTrees = 160;

  state: State = {
    hoveredTerm: null
  };

  termsForFrameworkWithID(frameworkID: number): Term[] {
    return this.props.terms.filter((term) => term.frameworkID === frameworkID);
  }

  /**
   * A size for a given D3 hierarchy based on the size and spacing for elements
   * at the top of the file.
   */
  sizeForTree = (tree: d3.HierarchyNode<Framework | Term>) =>
    new Size(
      (tree.height + 1) * (this.elementSize.width + this.elementSpacing.width),
      tree.leaves().length *
        (this.elementSize.height + this.elementSpacing.height)
    );

  hasRelatedTerms(term: Term): boolean {
    if (term.relatedTerms == null || term.relatedTerms.length === 0) {
      return false;
    }

    const { leftFramework, rightFramework } = this.props;

    if (leftFramework == null || rightFramework == null) {
      return false;
    }

    let otherFramework;
    if (leftFramework.identifier === term.frameworkID) {
      otherFramework = rightFramework;
    } else if (rightFramework.identifier === term.frameworkID) {
      otherFramework = leftFramework;
    } else {
      return false;
    }

    const otherTerms = this.termsForFrameworkWithID(otherFramework.identifier);

    if (term.relatedTerms == null) {
      return false;
    }

    return (
      intersection(
        term.relatedTerms.map((t) => t.identifier),
        otherTerms.map((t) => t.identifier)
      ).length > 0
    );
  }

  /**
   * Generate a layout for the visualization including the size which should be
   * used for the `viewBox` of the SVG and the rectangular frames of the left
   * and right trees.
   */
  layout = (
    leftFramework: Framework | null,
    rightFramework: Framework | null
  ): {
    size: Size;
    leftTree: d3.HierarchyPointNode<Framework | Term> | null;
    rightTree: d3.HierarchyPointNode<Framework | Term> | null;
  } => {
    if (!leftFramework && !rightFramework) {
      return {
        size: new Size(600, 200),
        leftTree: null,
        rightTree: null
      };
    }

    const leftTree = leftFramework && this.treeForFramework(leftFramework);
    const rightTree = rightFramework && this.treeForFramework(rightFramework);

    // If either the left or right is missing, use the opposite to make a
    // symmetrical layout. We already checked at least one exists.
    let leftSize = leftTree && this.sizeForTree(leftTree);
    let rightSize = rightTree && this.sizeForTree(rightTree);
    leftSize = leftSize || rightSize || new Size(0, 0);
    rightSize = rightSize || leftSize || new Size(0, 0);

    const size = new Size(
      leftSize.width + rightSize.width + this.spaceBetweenTrees,
      Math.max(leftSize.height, rightSize.height)
    );

    const contentRect = size.rect.inset(this.margin);

    const leftBounds = contentRect.insetRight(
      rightSize.width + this.spaceBetweenTrees
    );
    const rightBounds = contentRect.insetLeft(
      leftSize.width + this.spaceBetweenTrees
    );

    return {
      size,
      leftTree: leftTree && this.layoutForTree(leftTree, leftBounds, 'right'),
      rightTree: rightTree && this.layoutForTree(rightTree, rightBounds, 'left')
    };
  };

  /**
   * Return a D3 hierarchy for a framework. Organizing all the terms into a tree
   * shaped object with a node referencing the framework at the root. Does not
   * include position (i.e. x,y coordiantes) for nodes.
   */
  treeForFramework = (
    framework: Framework
  ): d3.HierarchyNode<Framework | Term> => {
    return stratifyFramework<Framework | Term>(
      framework,
      this.termsForFrameworkWithID(framework.identifier)
    );
  };

  /**
   * Evalutes the layout for a tree of a framework and its terms. Scales the
   * tree into the `contentRect` and orients it facing left or right.
   */
  layoutForTree(
    tree: d3.HierarchyNode<Framework | Term>,
    contentRect: Rect,
    flowDirection: 'left' | 'right'
  ): d3.HierarchyPointNode<Framework | Term> {
    const treemap = d3Tree().size(contentRect.size.toArrayReversed());

    const treeLayout = treemap(tree) as d3.HierarchyPointNode<Framework | Term>;

    transformCoordinates(
      treeLayout,
      flowDirection === 'left'
        ? ({ x, y }) => ({ x: contentRect.x + (contentRect.width - y), y: x })
        : ({ x, y }) => ({ x: contentRect.x + y, y: contentRect.y + x })
    );

    return treeLayout;
  }

  /**
   * Evaluate a set of connections between related nodes in the two frameworks.
   * Returns an empty set without two frameworks.
   */
  connections(
    leftTree: d3.HierarchyPointNode<Framework | Term> | null,
    rightTree: d3.HierarchyPointNode<Framework | Term> | null
  ) {
    if (!leftTree || !rightTree) {
      return [];
    }

    const connections: {
      left: d3.HierarchyPointNode<Framework | Term>;
      right: d3.HierarchyPointNode<Framework | Term>;
      relatedness: number;
    }[] = [];

    iterateCombinationsBetween(
      leftTree.leaves(),
      rightTree.leaves(),
      (left, right) => {
        if (!('relatedTerms' in left.data) || left.data.relatedTerms == null) {
          return;
        }
        const relationship = left.data.relatedTerms.find(
          (t) => t.identifier === right.data.identifier
        );
        if (relationship) {
          connections.push({
            left,
            right,
            relatedness: relationship.relatedness
          });
        }
      }
    );

    return connections;
  }

  // Rendering

  /**
   * Renders the connections between the two frameworks.
   */
  renderConnections(
    connections: {
      left: d3.HierarchyPointNode<Framework | Term>;
      right: d3.HierarchyPointNode<Framework | Term>;
      relatedness: number;
    }[]
  ) {
    const { hoveredTerm } = this.state;

    return (
      <g className="links">
        {connections.map(({ left, right, relatedness }, index) => (
          <path
            key={index}
            className="related-term-link"
            d={linkPath(
              {
                x: left.x + this.elementSize.width / 2,
                y: left.y
              },
              {
                x: right.x - this.elementSize.width / 2,
                y: right.y
              }
            )}
            style={{
              strokeWidth: strokeWidthScale(relatedness),
              opacity:
                hoveredTerm != null &&
                left.data.identifier !== hoveredTerm.identifier &&
                right.data.identifier !== hoveredTerm.identifier
                  ? 0.2
                  : 1
            }}
          />
        ))}
      </g>
    );
  }

  /**
   * Renders just one of the two framework trees.
   */
  renderTree(
    tree: d3.HierarchyPointNode<Framework | Term>,
    flowDirection: 'left' | 'right'
  ) {
    const xScale = flowDirection === 'left' ? -1 : 1;
    const nodes = tree.descendants();
    const framework = tree.data as Framework; // Root element data is the framework
    const { hoveredTerm } = this.state;

    return (
      <g>
        {nodes.slice(1).map((node) => (
          <path
            key={node.id}
            className="link"
            d={linkPath(
              {
                x: node.x - (xScale * this.elementSize.width) / 2,
                y: node.y
              },
              {
                x: node.parent!.x + (xScale * this.elementSize.width) / 2,
                y: node.parent!.y
              }
            )}
          />
        ))}

        {nodes.map((node) => (
          <FrameworkGraphNode
            key={node.id}
            color={framework.color}
            size={this.elementSize}
            node={node}
            dimmed={
              hoveredTerm != null &&
              node.data !== hoveredTerm &&
              !termsAreRelated(node.data as any, hoveredTerm)
            }
            onMouseEnter={
              this.hasRelatedTerms(node.data as any)
                ? () => {
                    this.setState({ hoveredTerm: node.data as any });
                  }
                : undefined
            }
            onMouseLeave={
              this.hasRelatedTerms(node.data as any)
                ? () => {
                    this.setState({ hoveredTerm: null });
                  }
                : undefined
            }
          />
        ))}
      </g>
    );
  }

  render() {
    const { leftFramework, rightFramework } = this.props;

    const { size, leftTree, rightTree } = this.layout(
      leftFramework,
      rightFramework
    );

    const connections = this.connections(leftTree, rightTree);

    return (
      <div className="CompareFrameworksGraph" aria-live="polite">
        <svg
          viewBox={`0 0 ${size.width} ${size.height}`}
          role="img"
          aria-label="Comparison Visualization"
        >
          {this.renderConnections(connections)}

          {leftTree && this.renderTree(leftTree, 'right')}
          {rightTree && this.renderTree(rightTree, 'left')}
        </svg>

        {leftFramework && rightFramework && leftTree && rightTree && (
          <ScreenReaderContent
            hierarchy={leftTree}
            comparisonHierarchy={rightTree}
          />
        )}
      </div>
    );
  }
}
