import './ScreenReaderContent.scss';

import React from 'react';
import { compact, isEmpty } from 'lodash';

import { Framework, Term } from 'types';

interface Props {
  hierarchy: d3.HierarchyNode<Framework | Term>;
  comparisonHierarchy: d3.HierarchyNode<Framework | Term>;
}

const ScreenReaderContent: React.FC<Props> = (props) => {
  const { hierarchy, comparisonHierarchy } = props;

  return (
    <div className="CompareFrameworksScreenReaderContent">
      <h3>
        Comparing {hierarchy.data.name} to {comparisonHierarchy.data.name}
      </h3>

      <ul>
        {hierarchy.children &&
          hierarchy.children.map((node) => (
            <Row
              key={node.data.identifier}
              node={node as any}
              comparisonTerms={
                comparisonHierarchy.leaves().map((d) => d.data) as any
              }
            />
          ))}
      </ul>
    </div>
  );
};

const relatednessDescriptor = (value: number) => {
  if (value > 0.6) {
    return 'strongly';
  }
  if (value > 0.3) {
    return 'moderately';
  }
  return 'weakly';
};

interface RowProps {
  node: d3.HierarchyNode<Term>;
  comparisonTerms: Term[];
}

const Row: React.FC<RowProps> = (props) => {
  const { node, comparisonTerms } = props;

  const relatedTerms = compact(
    node.data.relatedTerms?.map((relationship) => {
      const relatedTerm = comparisonTerms.find(
        (term) => term.identifier === relationship.identifier
      );

      return relatedTerm
        ? { ...relatedTerm, relatedness: relationship.relatedness }
        : null;
    })
  );

  return (
    <li>
      <div>{node.data.name}</div>
      <ul>
        {node.children ? (
          node.children.map((childNode) => (
            <Row
              key={childNode.data.identifier}
              node={childNode}
              comparisonTerms={comparisonTerms}
            />
          ))
        ) : !isEmpty(relatedTerms) ? (
          <div>
            <div>Related Terms</div>
            <ul>
              {relatedTerms.map((term) => (
                <li key={term.identifier}>
                  {term.name}, {relatednessDescriptor(term.relatedness)} related
                </li>
              ))}
            </ul>
          </div>
        ) : null}
      </ul>
    </li>
  );
};

export default ScreenReaderContent;
