import { without } from 'lodash';

/**
 * Add an item into an array if not already present, otherwise remove it.
 * Returns a new array without mutating the original.
 */
export function arrayTogglingElement<T>(array: T[], element: T): T[] {
  if (array.includes(element)) {
    return without(array, element);
  } else {
    return array.concat(element);
  }
}

/**
 * Invoke a function on each pair of elements between two arrays.
 */
export function iterateCombinationsBetween<T>(
  left: T[],
  right: T[],
  callback: (a: T, b: T) => void
) {
  left.forEach((leftElement) => {
    right.forEach((rightElement) => {
      callback(leftElement, rightElement);
    });
  });
}

export function replaceItemAtIndex<T>(
  array: T[],
  index: number,
  newValue: T
): T[] {
  return [...array.slice(0, index), newValue, ...array.slice(index + 1)];
}

export function removeItemAtIndex<T>(array: T[], index: number): T[] {
  return [...array.slice(0, index), ...array.slice(index + 1)];
}
